import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import HomeLayout from '../layout/home-layout';
// import HomePage from '../pages/home'
import DealPage from '../pages/deal';
import ErrorPage from "@/pages/error";
/*const HomePage = lazy(() => import("@/pages/home"));
const DealPage = lazy(() => import("@/pages/deal"));*/
const basename = "/";
export const router = createBrowserRouter([
    {
        path: "/",
        element: React.createElement(HomeLayout, null),
        children: [
            {
                path: "/",
                element: React.createElement(ErrorPage, null),
            },
            {
                path: "/deal/:external_id",
                element: React.createElement(DealPage, null),
            },
            {
                path: "*",
                element: React.createElement(Navigate, { to: "/", replace: true })
            }
        ],
    },
], { basename });
