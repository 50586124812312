import React from "react";
import { Button, Frame, ShowList, Title } from "@/components";
import './styles.scss';
import DocumentBlock from "@/components/chunks/document-block";
import { useRequest } from "@/hooks/useRequest";
import dayjs from "dayjs";
const Right = require('public/chunks/arrow-right-short.svg');
const VideoCall = ({ deal, isLoading, isDisabled, onReject, external_id }) => {
    var _a;
    const { isLoading: isLoadingOnSignInit, fetch: onSignInit } = useRequest({
        method: 'post',
        url: `/api/services/did/${external_id}/sign-init/`
    }, {
        onSuccess: (res) => window.open(res === null || res === void 0 ? void 0 : res.url, "_self")
    });
    const docData = [
        {
            title: 'Согласие',
            docs: [
                { name: 'Посмотреть документ согласия', link: (_a = deal === null || deal === void 0 ? void 0 : deal.document) === null || _a === void 0 ? void 0 : _a.file },
            ]
        }
    ];
    return React.createElement("div", { className: "video-call__wrapper" },
        React.createElement("div", { className: "video-call" },
            React.createElement("div", { className: 'p-x-10' },
                React.createElement(Title, { variant: 'h4', extraClass: "t-a-c", text: (deal === null || deal === void 0 ? void 0 : deal.title) || `Процесс онлайн получения согласия супруги/га по заявке №${deal === null || deal === void 0 ? void 0 : deal.enis_id} от ${dayjs().format('DD.MM.YYYY')} на Ипотеку.` })),
            React.createElement("div", { className: 'video-call__container' },
                React.createElement(Frame, { deal: deal })),
            React.createElement("div", { className: 'video-call__footer' },
                React.createElement("div", { className: 'd-f j-c-c f-d-c gg-20 w100', style: { maxWidth: '500px' } },
                    React.createElement("div", { id: 'documents' },
                        React.createElement(ShowList, { list: docData }, (d, i) => React.createElement(DocumentBlock, Object.assign({ key: i }, d)))),
                    React.createElement("div", { className: 'pages-buttons__group mobile-btn' },
                        React.createElement(Button, { onClick: () => onReject(), label: 'Отменить условия', className: 'link' }),
                        React.createElement(Button, { disabled: (isLoading === null || isLoading === void 0 ? void 0 : isLoading.isLoadingDeal) || isLoadingOnSignInit || (deal === null || deal === void 0 ? void 0 : deal.state) !== 'TEMPLATE_PREPARED', loading: (isLoading === null || isLoading === void 0 ? void 0 : isLoading.isLoadingDeal) || isLoadingOnSignInit, label: 'Подписать согласие', className: 'forward', svg_right: Right, onClick: () => onSignInit() }))))));
};
export default VideoCall;
