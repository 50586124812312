import React from 'react';
import './styles.scss';
import SVG from 'react-inlinesvg';
import { ShowIf } from "@/components";
const Loading = require('public/loading.svg');
const Download = require('public/download.svg');
const Button = ({ style, className, disabled, onClick, loading, svg_left, label, download, onDownload, remove, onRemove, svg_right, isSimple, caption, extraClass = '' }) => {
    return React.createElement("button", { className: `btn ${className ? `btn-${className}` : ''} ${disabled ? 'disabled' : ''} ${extraClass}`, style: style, disabled: disabled, onClick: onClick },
        React.createElement(ShowIf, { condition: loading },
            React.createElement("div", { className: 'btn-loading' },
                React.createElement(SVG, { src: Loading }))),
        React.createElement(ShowIf, { condition: !!svg_left },
            React.createElement("div", { className: 'svg-left m-r-10' },
                React.createElement(SVG, { src: svg_left, height: 12 }))),
        React.createElement(ShowIf, { condition: !!label },
            React.createElement("div", { className: (className && !isSimple) ? `${'btn-' + className}__label-container` : 'btn__label-container' },
                React.createElement("span", { className: (className && !isSimple) ? `${'btn-' + className}__label` : 'btn__label' }, label),
                caption &&
                    React.createElement("span", { className: (className && !isSimple) ? `${'btn-' + className}__caption` : 'btn__caption' }, caption))),
        React.createElement(ShowIf, { condition: download },
            React.createElement("div", { className: `svg-right download` },
                React.createElement(SVG, { src: Download, onClick: (event) => {
                        event.stopPropagation();
                        onDownload(event);
                    } }))),
        React.createElement(ShowIf, { condition: remove },
            React.createElement("div", { className: `svg-right remove` })),
        React.createElement(ShowIf, { condition: !!svg_right },
            React.createElement("div", { className: `svg-right` },
                React.createElement(SVG, { src: svg_right }))));
};
export default Button;
