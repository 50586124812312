export const statuses = [
    {
        type: 'NEW',
        name: 'Новая сделка',
        component: 'VideoCall'
    },
    {
        type: 'IN_PROGRESS',
        name: 'В работе',
        component: 'VideoCall'
    },
    {
        type: 'TEMPLATE_PREPARED',
        name: 'Шаблон документа получен',
        component: 'VideoCall'
    },
    {
        type: ['WAITING_ACCESS_GRANT', 'NEED_OTP_VERIFY'],
        name: 'На подтверждение ОТП',
        component: 'StepOtp'
    },
    {
        type: 'DIGITAL_ACCESS_GRANTED',
        name: 'Доступ к ЦД получен',
        component: 'Success'
    },
    {
        type: 'COMPLETED',
        name: 'Сделка завершена',
        component: 'Completed'
    },
    {
        type: 'REJECTED',
        name: 'Отказано',
        component: 'Rejected'
    },
    {
        type: '',
        name: 'Не найден',
        component: 'NotFound'
    }
];
