import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.scss";
import '@/translate/i18n';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ru';
import { router } from "@/routes";
import { Toaster } from 'react-hot-toast';
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.locale('ru');
const App = () => {
    return React.createElement(React.Fragment, null,
        React.createElement(Toaster, { position: "top-right", reverseOrder: false, gutter: 8 }),
        React.createElement(RouterProvider, { router: router }));
};
const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(React.createElement(App, null));
