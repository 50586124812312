import React from "react";
import "./styles.scss";
import { Outlet } from "react-router-dom";
const HomeLayout = ({ showSidebar, showStepsSidebar }) => {
    const withSidebar = (React.createElement("div", { id: "layout__wrapper", className: "layout__wrapper" },
        React.createElement("main", null,
            React.createElement(Outlet, null))));
    return React.createElement("div", { className: "layout" },
        React.createElement(Outlet, null));
};
export default HomeLayout;
