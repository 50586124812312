import React from 'react';
import './index.scss';
import { Header } from "@/components";
import Error from "@/components/chunks/steps/error";
export default function ErrorPage() {
    return React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement("main", { className: 'home-page__wrapper' },
            React.createElement("div", { className: "container" },
                React.createElement(Error, { error_message: 'Страница не найдена' }))));
}
