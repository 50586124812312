import React from 'react';
import './index.scss';
const Title = ({ variant = 'h4', text, extraClass = '', style = {} }) => {
    return React.createElement(variant, {
        style,
        className: `title ${variant} ${extraClass}`,
        children: text
    });
};
export default Title;
