import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import kz_home from './kz_home.json';
import en_home from './en_home.json';
import ru_home from './ru_home.json';

let lang = 'ru';

if (localStorage.getItem('lang')) {
	lang = localStorage.getItem('lang');
} else {
	localStorage.setItem('lang', 'ru');
}

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		react: {
			useSuspense: true,
			wait: true,
		},
		lng: lang,
		fallbackLng: lang,
		debug: false,
		resources: {
			ru: {
				home: ru_home,
			},
			kz: {
				home: kz_home,
			},
			en: {
				home: en_home,
			},
		},
		detection: {
			order: ['queryString', 'cookie'],
			cache: ['cookie'],
		},
		interpolation: {
			escapeValue: false,
		},
		ns: ['home'],
		defaultNS: 'home',
		nsSeparator: '.',
		nonExplicitWhitelist: true,
	});

export {i18n}
