export const showErrorHelper = (callBack) => (err) => {
    var _a, _b, _c, _d;
    if (((_a = err === null || err === void 0 ? void 0 : err.data) === null || _a === void 0 ? void 0 : _a.code) === 'token_not_valid')
        return;
    Object === null || Object === void 0 ? void 0 : Object.entries((_b = err.data) !== null && _b !== void 0 ? _b : {}).forEach(([key, val]) => {
        if (Array.isArray(val)) {
            val.forEach(message => {
                if (typeof message === 'string')
                    return callBack(`${key}: ${message}`);
            });
        }
        else if (val === null || val === void 0 ? void 0 : val.detail)
            return callBack(`${key}: ${val === null || val === void 0 ? void 0 : val.detail}`);
    });
    if (Array.isArray(err === null || err === void 0 ? void 0 : err.data))
        err === null || err === void 0 ? void 0 : err.data.map((err) => typeof err === 'string' && callBack(err));
    if ((_c = err === null || err === void 0 ? void 0 : err.data) === null || _c === void 0 ? void 0 : _c.detail)
        return callBack(err.data.detail);
    if ((_d = err === null || err === void 0 ? void 0 : err.data) === null || _d === void 0 ? void 0 : _d.error)
        return callBack(err.data.error);
    else if (err === null || err === void 0 ? void 0 : err.detail)
        return callBack(err.detail);
    else if (err === null || err === void 0 ? void 0 : err.error)
        return callBack(err.error);
    else if ((err === null || err === void 0 ? void 0 : err.status) === 500)
        return callBack('Ошибка сервера');
};
