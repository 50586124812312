import React from "react";
import './styles.scss';
import { Button, ShowGroup, ShowList, ShowSwitch, Title } from "@/components";
import SVG from "react-inlinesvg";
const CheckCircle = require('public/chunks/check-circle-fill.svg');
const Success = ({ options }) => {
    return React.createElement("div", { className: "success-step d-f j-c-c a-i-c", style: { minHeight: 'calc(100vh - 80px)' } },
        React.createElement("div", { className: " d-f j-c-c a-i-c f-d-c gg-20" },
            React.createElement(SVG, { src: CheckCircle }),
            React.createElement("div", { className: 't-a-c' },
                React.createElement(ShowSwitch, { conditions: [Array.isArray(options === null || options === void 0 ? void 0 : options.success_message)] },
                    React.createElement(ShowList, { list: options === null || options === void 0 ? void 0 : options.success_message }, (message, i) => React.createElement(Title, { key: i, variant: 'h1', text: message })),
                    React.createElement(Title, { variant: 'h1', text: options === null || options === void 0 ? void 0 : options.success_message }))),
            React.createElement("div", { className: 'p-x-10' },
                React.createElement(ShowGroup, { conditions: [false, false] },
                    React.createElement(Button, { onClick: () => window.open('https://ipoteka.bankffin.kz'), label: 'Перейти на сайт Freedom Bank ', className: 'outline' }),
                    React.createElement("div", null)))));
};
export default Success;
