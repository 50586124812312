var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import request from "@/api";
import { useEffect, useRef, useState } from "react";
import { clearInterval } from "timers";
import toast from "react-hot-toast";
import { showErrorHelper } from "@/helpers/showErrorHelper";
export const useRequest = (_a, opt) => {
    var { url, data, retry } = _a, options = __rest(_a, ["url", "data", "retry"]);
    const { method = 'get', showError = true } = options, params = __rest(options, ["method", "showError"]);
    const intervalRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState({});
    const [error, setError] = useState({});
    const requestHandler = (body) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        setError({});
        try {
            const res = yield request(method)(url, Object.assign(Object.assign({}, data), (body || {})), params);
            setResponse(res.data);
            (opt === null || opt === void 0 ? void 0 : opt.onSuccess) && opt.onSuccess(res.data);
        }
        catch (error) {
            const err = error === null || error === void 0 ? void 0 : error.response;
            setError(err === null || err === void 0 ? void 0 : err.data);
            (opt === null || opt === void 0 ? void 0 : opt.onError) && opt.onError(err);
            showError && showErrorHelper(toast.error)(err);
        }
        finally {
            setIsLoading(false);
        }
    });
    useEffect(() => {
        if (method.toLowerCase() === 'get')
            requestHandler();
        if (method.toLowerCase() === 'get' && retry) {
            intervalRef.current = setInterval(requestHandler, retry);
        }
        return () => typeof intervalRef.current === 'number' && clearInterval(intervalRef.current);
    }, []);
    return { isLoading, data: response, error, fetch: requestHandler };
};
