import React, { useCallback, useEffect, useRef } from 'react';
import './index.scss';
import { Header, ShowSwitch } from "@/components";
import { useRequest } from "@/hooks/useRequest";
import { useParams } from "react-router";
import { statuses } from "@/options/statuses";
import { wsURL } from "@/api/api-config";
import toast from "react-hot-toast";
import { createFlowChunk } from "@/helpers/createFlowChunk";
const uuid = 'c465b4e9-7f5d-431c-73d6-7bd24cb5d741';
export default function DealPage(arg) {
    var _a;
    const wsRef = useRef(null);
    const { external_id } = useParams();
    const { isLoading: isLoadingDeal, data: deal, fetch, error } = useRequest({
        // retry: 10000,
        showError: true,
        url: `/api/deals/${external_id}/`
    });
    const { isLoading: isLoadingOnReject, fetch: onReject } = useRequest({
        method: 'post',
        url: `/api/deals/${external_id}/reject/`
    });
    const scrolltoDocuments = () => {
        const docElem = document.getElementById("documents");
        docElem === null || docElem === void 0 ? void 0 : docElem.scrollIntoView({ behavior: 'smooth' });
    };
    const webSocketConnection = useCallback(() => {
        if (wsRef.current)
            return;
        wsRef.current = new WebSocket(`${wsURL}/deal_state_update_${deal === null || deal === void 0 ? void 0 : deal.id}`);
        wsRef.current.onmessage = (event) => {
            const response = JSON.parse(event === null || event === void 0 ? void 0 : event.data);
            console.log('webSocket onmessage', response);
            if (response === null || response === void 0 ? void 0 : response.link) {
                scrolltoDocuments();
                toast.success('Шаблон документа получен! Вы можете посмотреть документ согласия', { duration: 5000 });
            }
            if ((response === null || response === void 0 ? void 0 : response.status) !== (deal === null || deal === void 0 ? void 0 : deal.state))
                fetch();
        };
        wsRef.current.onclose = (e) => {
            console.log('webSocket onclose', e);
        };
        wsRef.current.onerror = function (error) {
            console.log('webSocket onerror', error);
        };
    }, [deal === null || deal === void 0 ? void 0 : deal.id, wsRef.current]);
    useEffect(() => {
        (deal === null || deal === void 0 ? void 0 : deal.id) && webSocketConnection();
        return () => {
            var _a;
            console.log('CLOSE', wsRef.current);
            (_a = wsRef.current) === null || _a === void 0 ? void 0 : _a.close();
        };
    }, [deal === null || deal === void 0 ? void 0 : deal.id]);
    const isLoading = {
        isLoadingDeal,
        isLoadingOnReject,
    };
    const isDisabled = {
        isLoadingDeal,
        isLoadingOnReject,
    };
    const options = {
        success_message: ['Спасибо!', 'Документ успешно получен']
    };
    console.log('DealPage', { external_id, deal, isLoadingDeal, isDisabled, error, });
    const conditionsFn = (status) => Array.isArray(status.type)
        ? status.type.includes(deal === null || deal === void 0 ? void 0 : deal.state)
        : (deal === null || deal === void 0 ? void 0 : deal.state) === status.type;
    const flowChunksHandler = (status) => {
        return createFlowChunk(status.component, Object.assign(Object.assign({}, status), { isLoading,
            isDisabled,
            options,
            onReject,
            external_id,
            deal }));
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement("main", { className: 'home-page__wrapper' },
            React.createElement("div", { className: "container" },
                React.createElement(ShowSwitch, { conditions: [!!((_a = Object.keys(error || {})) === null || _a === void 0 ? void 0 : _a.length)] },
                    React.createElement(React.Fragment, null, createFlowChunk('Error', { type: 'error', error_message: (error === null || error === void 0 ? void 0 : error.detail) || error })),
                    React.createElement(ShowSwitch, { conditions: statuses.map(conditionsFn) }, statuses.map(flowChunksHandler))))));
}
