import React, { useEffect, useRef } from 'react';
import './styles.scss';
import { OtpInput } from "@/components";
import { useTranslation } from "react-i18next";
const PhoneInfo = ({ phone, onBackToResetPass }) => {
    const { t } = useTranslation();
    return React.createElement("div", { className: 'otp-form__phone-info' },
        React.createElement("span", { className: 'otp-form__light-text' }, t('Код отправлен на номер ')),
        React.createElement("h5", { className: 'otp-form__phone m-t-10' }, phone));
};
const OtpForm = ({ value, phone, changeValue, onResend, isLoading, onResetPass, isDisabled, otpLength = 4, timerLength = 120, extraClass = '' }) => {
    const { t } = useTranslation();
    const intervalRef = useRef(null);
    const [finished, setFinished] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [timer, setTimer] = React.useState('');
    useEffect(() => {
        startTimer(timerLength);
        return () => clearInterval(intervalRef.current);
    }, []);
    function startTimer(duration) {
        setFinished(false);
        var timer = duration;
        var minutes;
        var seconds;
        intervalRef.current = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);
            const transformValue = (time) => String(time).length > 1 ? String(time) : `0${time}`;
            setTimer(`${transformValue(minutes)}:${transformValue(seconds)}`);
            if (--timer < 0) {
                timer = duration;
                setFinished(true);
                clearInterval(intervalRef.current);
            }
        }, 1000);
    }
    const resendHandler = () => {
        onResend(() => startTimer(timerLength));
        changeValue('');
    };
    return React.createElement("div", { className: 'otp-form ' + extraClass },
        React.createElement("h4", { className: 'otp-form__title' }, t('На ваш номер выслан код')),
        React.createElement(PhoneInfo, { phone: phone, onBackToResetPass: onResetPass }),
        React.createElement("div", { className: 'otp-form__inputs' },
            React.createElement("span", { className: 'otp-form__light-text m-b-20' }, t('Введите код из SMS')),
            React.createElement(OtpInput, { disabled: isDisabled, length: otpLength, value: value, onChange: changeValue })));
};
export default OtpForm;
