import React from "react";
import "./styles.scss";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Logo = require("public/logo.svg");
const DarkLogo = require("public/logo-dark-theme.svg");
const Header = ({}) => {
    const { t } = useTranslation();
    return React.createElement(React.Fragment, null,
        React.createElement("header", { className: `header__wrapper indent ` },
            React.createElement("div", { className: "header" },
                React.createElement("div", { className: "header__left" },
                    React.createElement("div", { className: "header__logo" },
                        React.createElement(Link, { to: "/" },
                            React.createElement(SVG, { src: Logo, width: 188 })))),
                React.createElement("div", { className: "header__right" }))));
};
export default Header;
