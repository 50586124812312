import React from "react";
const ShowIf = ({ condition, children, wrapElem }) => {
    const { elem, className, props = {} } = wrapElem || {};
    const insertChildren = Array.isArray(children) ? children : [children];
    const element = elem ?
        React.cloneElement(elem, Object.assign({ className: className || '' }, props), insertChildren) :
        React.cloneElement(React.createElement(React.Fragment, null), {}, insertChildren);
    return condition ? element : null;
};
export default ShowIf;
