import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { ShowList } from "@/components";
const OtpInput = ({ length = 4, onChange, className, value, disabled }) => {
    const [otp, setOtp] = useState(Array.from({ length }, () => ''));
    const otp_ref = useRef([]);
    useEffect(() => {
        var _a;
        (_a = otp_ref.current[0]) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    useEffect(() => {
        getNextFocusRef(otp);
        onChange(otp.join(''));
    }, otp);
    useEffect(() => {
        if (value) {
            setOtp(value === null || value === void 0 ? void 0 : value.split('').concat(Array.from({ length: length - value.length }, () => '')));
        }
    }, [value]);
    const getNextFocusRef = (otp) => {
        otp.forEach((cur, idx, arr) => {
            var _a, _b;
            if (arr.length - 1 === idx && cur.length > 0)
                return (_a = otp_ref.current[idx]) === null || _a === void 0 ? void 0 : _a.blur();
            if (cur.length > 0)
                (_b = otp_ref.current[idx + 1]) === null || _b === void 0 ? void 0 : _b.focus();
        });
    };
    return React.createElement("div", { className: 'otp-input__container ' + className },
        React.createElement(ShowList, { list: Array.from({ length }, (_, idx) => idx) }, (key) => React.createElement("div", { key: key, className: 'otp-input__item' },
            React.createElement("input", { type: 'tel', className: 'otp-input__input', ref: (instance) => otp_ref.current[key] = instance, value: otp[key], disabled: disabled, onChange: (e) => setOtp(prev => {
                    const copy = [...prev];
                    copy[key] = e.target.value;
                    return copy;
                }) }))));
};
export default OtpInput;
