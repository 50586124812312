import React from 'react';
import './index.scss';
import { Button, ShowIf, ShowList, Title } from "@/components";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { downloadFileHelper } from "@/helpers/downloadFileHelper";
const Download = require('public/chunks/download.svg');
const Document = require('public/chunks/document.svg');
export const DocumentCard = ({ name, link }) => {
    return (React.createElement("div", { className: `document-block__document-card ${!link && 'disabled'}`, onClick: () => link && downloadFileHelper(link, name, { onNewWindow: true }) },
        React.createElement(SVG, { src: Document, width: 20, height: 20 }),
        React.createElement(Title, { text: name, style: { margin: '0 0 0 8px', fontSize: '14px', flex: '1' } }),
        React.createElement(ShowIf, { condition: link },
            React.createElement(Button, { label: '', svg_left: Download, className: 'icon' }))));
};
const DocumentBlock = ({ title, docs }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: 'document-block__wrapper' },
        React.createElement(Title, { extraClass: "sub-title_title", text: t(title), style: { margin: '0' } }),
        React.createElement(ShowList, { list: docs }, (doc) => {
            return React.createElement(DocumentCard, { key: doc.name, name: t(doc.name), link: doc.link });
        })));
};
export default DocumentBlock;
