import React from 'react';
import { downloadFileHelper } from "@/helpers/downloadFileHelper";
import { BaseContainer, ShowList, Title } from "@/components";
// @ts-ignore
// import IdCard from 'public/chunks/id_card.png'
import Attorney from 'public/chunks/attorney.png';
import DocumentBlock from "@/components/chunks/document-block";
const Completed = ({ deal }) => {
    var _a, _b;
    const docData = [
        {
            title: 'Подписаные документы',
            docs: [
                { name: 'Документ согласия', link: (_a = deal === null || deal === void 0 ? void 0 : deal.document) === null || _a === void 0 ? void 0 : _a.file },
            ]
        }
    ];
    return React.createElement("section", { className: `step__wrapper` },
        React.createElement("section", { className: `step__item ` },
            React.createElement("div", { className: "p-x-10" },
                React.createElement(Title, { variant: 'h1', text: 'Поздравляем! Все шаги пройдены!', style: { marginBottom: '8px' } })),
            React.createElement(BaseContainer, null,
                React.createElement(Title, { text: 'Спасибо, Банк получил Ваше согласие.', style: { marginBottom: '8px' } }),
                React.createElement("div", { className: "d-f a-i-c m-y-20" },
                    React.createElement("img", { onClick: () => {
                            var _a;
                            downloadFileHelper((_a = deal === null || deal === void 0 ? void 0 : deal.digital_document) === null || _a === void 0 ? void 0 : _a.document, 'УДЛ', { onNewWindow: true });
                        }, className: 'm-r-20 pointer', src: Attorney, alt: "id_card", style: { width: '64px' } }),
                    React.createElement("div", { style: { color: '#B7BBBD' } },
                        "\u041D\u043E\u043C\u0435\u0440: ", (_b = deal === null || deal === void 0 ? void 0 : deal.digital_document) === null || _b === void 0 ? void 0 :
                        _b.uuid))),
            React.createElement(BaseContainer, null,
                React.createElement(ShowList, { list: docData }, (d) => React.createElement(DocumentBlock, Object.assign({}, d))))));
};
export default Completed;
