var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, OtpForm, Text, Title } from "@/components";
import { useRequest } from "@/hooks/useRequest";
const Right = require('public/chunks/arrow-right-short.svg');
const StepOtp = (_a) => {
    var _b, _c, _d;
    var { deal, isLoading } = _a, props = __rest(_a, ["deal", "isLoading"]);
    const optLength = 6;
    const { t } = useTranslation();
    const [params, setParams] = useState({});
    const [isDisabledOtp, setIsDisabledOtp] = useState(false);
    const { isLoading: isLoadingOnVerify, fetch: onVerify } = useRequest({
        method: 'post',
        url: `/api/services/ddocs/${props === null || props === void 0 ? void 0 : props.external_id}/verify-otp/`,
    }, {
        onError: () => {
            setIsDisabledOtp(false);
        },
        onSuccess: () => {
            setIsDisabledOtp(true);
        },
    });
    const changeParam = (key) => (value) => {
        setParams(prev => (Object.assign(Object.assign({}, prev), { [key]: value })));
    };
    return (React.createElement("section", { className: `step__wrapper` },
        React.createElement("section", { className: `step__item ` },
            React.createElement("div", { className: 'p-x-20' },
                React.createElement(Title, { text: 'Цифровое удостоверение личности' }),
                React.createElement(Text, { text: ['Для предоставления временного доступа Банку к своему цифровому удостоверению личности, пожалуйста, подтвердите согласие SMS-кодом с номера 1414.    '] })),
            React.createElement(OtpForm, { value: params === null || params === void 0 ? void 0 : params.otp, otpLength: optLength, isDisabled: isDisabledOtp || isLoadingOnVerify, phone: (_c = (_b = deal === null || deal === void 0 ? void 0 : deal.client) === null || _b === void 0 ? void 0 : _b.principal) === null || _c === void 0 ? void 0 : _c.phone, changeValue: changeParam === null || changeParam === void 0 ? void 0 : changeParam('otp') }),
            React.createElement("div", { className: "mobile-btn", style: { display: 'flex', justifyContent: 'end' } },
                React.createElement(Button, { onClick: () => onVerify(params), loading: isLoadingOnVerify, label: t('Продолжить'), disabled: ((_d = params === null || params === void 0 ? void 0 : params.otp) === null || _d === void 0 ? void 0 : _d.length) < optLength || isLoadingOnVerify, caption: t('Перейти к подписанию документов'), className: 'forward', svg_right: Right })))));
};
export default StepOtp;
